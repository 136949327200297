const state = require('../store/state.json');

let recordSitemap;

try {
  /* eslint-disable-next-line */
  const recordSlugs = require('../store/record-slugs.json');
  recordSitemap = {
    slugs: recordSlugs,
  };
} catch (e) {
  recordSitemap = {
    ignoreRoute: true,
  };
}

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (state.directories.length < 2) {
        next({
          name: 'directory-home',
          params: { directory: state.directories[0].slug },
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      sitemap: {
        ignoreRoute: true,
      },
    },
  },
  {
    path: '/:directory',
    component: () => import('../views/Directory/Home.vue'),
    meta: {
      sitemap: {
        slugs: state.directories.map((directory) => directory.slug),
      },
    },
    children: [
      {
        path: ':facet?/:value?',
        name: 'directory',
        component: () => import('../views/Directory/SearchResults.vue'),
        meta: {
          sitemap: {
            slugs: state.directories.map((directory) => ({
              slug: directory.slug,
              // Optional parameters are still required to be set in the sitemap
              facet: '',
              value: '',
            })),
          },
        },
      },
      {
        path: 'home',
        name: 'directory-home',
        component: () => import('../views/Directory/Landing.vue'),
        beforeEnter: (to, from, next) => {
          const directory = state.directories.find((dir) => dir.slug === to.params.directory);
          const primaryFilter = directory.primary_filter.field || '';
          const primaryFilterSettings = directory.primary_filter.settings || '';

          if (!primaryFilter || !primaryFilterSettings) {
            next({
              name: 'directory',
              params: { directory: directory.slug },
            });
          } else {
            next();
          }
        },
        meta: {
          sitemap: {
            slugs: state.directories.map((directory) => directory.slug),
          },
        },
      },
      {
        path: 'facet/:facetName',
        name: 'facet-home',
        component: () => import('../views/Directory/FacetHome.vue'),
        meta: {
          sitemap: {
            ignoreRoute: true,
          },
        },
      },
      {
        path: '/:directory/:record',
        name: 'record',
        component: () => import('../views/Record.vue'),
        meta: {
          sitemap: recordSitemap,
        },
      },
    ],
  },
];
