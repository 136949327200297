export const namespaced = true;

export const getters = {
  all: (s, g, rS, rootGetters) => rootGetters['directory/current']?.fields ?? [],

  facets: (s, moduleGetter) => moduleGetter.all
    .filter((field) => field.facet === true),

  get: (s, moduleGetter) => moduleGetter.all
    .filter((field) => field.facet !== true)
    .filter((field) => field.type !== 'link')
    .filter((field) => field.key !== 'name'),

  links: (s, moduleGetter) => moduleGetter.all
    .filter((field) => field.type === 'link'),
};
