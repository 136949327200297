export default {
  methods: {
    getUrl(key, value) {
      const filterName = this.slug(key);
      const filterValue = this.slug(value);
      return `${filterName}/${filterValue}`;
    },
    slug(text) {
      return text.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
    },

  },
};
