import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight, faAngleDown, faPhone, faGlobe, faSearch, faUser, faEnvelope,
  faMapMarker, faArrowLeft, faArrowToLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

library.add(
  faChevronRight, faAngleDown, faPhone, faGlobe,
  faSearch, faFacebookF, faTwitter, faLinkedinIn, faUser, faEnvelope,
  faMapMarker, faArrowLeft, faArrowToLeft,
);
