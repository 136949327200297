export const namespaced = true;

export const state = {
  markers: {},
};

export const mutations = {
  POPULATE_MARKER(state, m) {
    state.markers[`m${m.mapMarker.metadata.id}`] = m.mapMarker;
    state.markers[`m${m.mapMarker.metadata.id}`].info = m.mapMarkerInfo;
  },
  CLEAR_MARKERS(state) {
    state.markers = {};
  },
};

export const actions = {
  populateMarker({ commit }, marker) {
    commit('POPULATE_MARKER', marker);
  },
  resetMarkers({ commit, state }) {
    if (state.markers && Object.keys(state.markers)) {
      Object.keys(state.markers).forEach((index) => {
        state.markers[index].setMap(null);
      });
      commit('CLEAR_MARKERS');
    }
  },
};
