import { createRouter, createWebHistory } from 'vue-router';
import qs from 'qs';

import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);

    return result || '';
  },
});

export default router;
