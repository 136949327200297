import { createApp } from 'vue';
import InstantSearch from 'vue-instantsearch/vue3/es';
import { createMetaManager, plugin as metaPlugin, defaultConfig } from 'vue-meta';
import VueGtag from 'vue-gtag';
import AppHome from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/app.scss';

// Init scripts
import bugsnagVue from './init/bugsnag';
import { FontAwesomeIcon, FontAwesomeLayers } from './init/fontawesome';
import './assets/tailwind.css';

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
});

const app = createApp(AppHome)
  .use(router)
  .use(store)
  .use(bugsnagVue)
  .use(InstantSearch)
  .use(metaManager)
  .use(metaPlugin);

if (store.state.analytics_id) {
  app.use(VueGtag, {
    config: { id: store.state.analytics_id },
  }, router);
}

app.component('fa', FontAwesomeIcon);
app.component('fa-layers', FontAwesomeLayers);

app.mount('#app');
