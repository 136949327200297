<template>
  <div class="footer">
    <div class="container columns is-vcentered">
      <div class="column byline has-text-centered-mobile">
        © {{ instanceName }} | Directory by <a href="https://datajoe.com" target="_blank" rel="noopener" class="is-underlined">DataJoe</a>
      </div>
      <div v-if="needsFacetPageLinks" class="column">
        <div class="is-size-7 has-text-centered">
          <span class="has-text-weight-bold">VIEW ALL ></span>
          <a v-for="facet in facets" :key="facet.key" :href="getFacetLink(facet)" class="mx-2">
            {{ facet.name }}
          </a>
        </div>
      </div>
      <div class="column has-text-right">
        <a href="https://datajoe.com" target="_blank" rel="noopener">
          <img
            src="https://s3-us-west-2.amazonaws.com/directory.covamd.com/poweredby.png"
            class="is-powered-by"
            alt="Powered by DataJoe"
          >
        </a>
        <div class="buttons m-0 is-inline-block">
          <Sharing />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Sharing from '@/components/Global/Sharing.vue';
import formatIgnoredUrl from '@/mixins/formatIgnoredUrl';

export default {
  mixins: [formatIgnoredUrl],
  computed: {
    ...mapState({
      instanceName: (state) => state.name,
    }),
    ...mapGetters({
      facets: 'fields/facets',
    }),
    needsFacetPageLinks() {
      return ['directory-home', 'facet-home', 'record'].includes(this.$route.name);
    },
  },
  methods: {
    getFacetLink(facet) {
      const facetName = this.slug(facet.name);
      return `/${this.$route.params.directory}/facet/${facetName}`;
    },
  },
  components: {
    Sharing,
  },
};
</script>
